import type { LoaderFunction } from '@remix-run/node';

import type { MetaDescriptor, MetaFunction } from '@remix-run/node';
import type { loader as rootLoader } from '~/root';
import type { loader as appLoader } from '~/routes/_app';
import { createBreadcrumbSchema } from '../schema/breadcrumb-schema';
import { createLDSchema, mergeLDSchema } from './create-ld-schema';
import type { CanonicalHreflangLoaderArgs } from '../api';
import { getCanonicalHreflang } from '../api';

export interface StaticParentLoaders extends Record<string, LoaderFunction> {
    root: typeof rootLoader;
    'routes/_app': typeof appLoader;
}

// Handles merging meta data between parent and child routes
export const mergeMeta = <
    T extends LoaderFunction | unknown = unknown,
    Matches extends Record<string, LoaderFunction> = {
        root: typeof rootLoader;
        'routes/_app': typeof appLoader;
    }
>(
    overrideFn: MetaFunction<T, Matches>,
    appendFn?: MetaFunction<T, Matches>
): MetaFunction<T & CanonicalHreflangLoaderArgs, Matches> => {
    return (arg) => {
        if (!arg.data) {
            return [];
        }
        // get meta from parent routes
        let mergedMeta = arg.matches.reduce((acc, match) => {
            return acc.concat(match.meta || []);
        }, [] as MetaDescriptor[]);

        // replace any parent meta with the same name or property with the override
        let overrides = overrideFn(arg);

        // dedupe from layout routes (_app._index.tsx)
        mergedMeta = [...new Set(mergedMeta)];

        for (let override of overrides) {
            let index = mergedMeta.findIndex(
                (meta) =>
                    ('name' in meta &&
                        'name' in override &&
                        meta.name === override.name) ||
                    ('property' in meta &&
                        'property' in override &&
                        meta.property === override.property) ||
                    ('title' in meta && 'title' in override)
            );
            if (index !== -1) {
                mergedMeta.splice(index, 1, override);
            }
        }

        // append any additional meta
        if (appendFn) {
            mergedMeta = mergedMeta.concat(appendFn(arg));
        }

        // Add breadcrumb schema to every page, except homepage
        if (arg.location.pathname !== '/') {
            const breadcrumbSchema = createLDSchema([
                createBreadcrumbSchema(arg),
            ]);
            mergedMeta = mergeLDSchema([...mergedMeta, ...breadcrumbSchema]);
        } else {
            mergedMeta = mergeLDSchema(mergedMeta);
        }

        // Defaults if missing, canonicals, or breadcrumb schema
        if (!hasCanonical(mergedMeta)) {
            const defaultCanonical = getCanonicalHreflang(arg);
            mergedMeta = mergedMeta.concat(defaultCanonical);
        }

        return mergedMeta;
    };
};

const hasCanonical = (mergedMeta: MetaDescriptor[]): boolean => {
    return !!mergedMeta.find((tag) => {
        const isRelLink = 'tagName' in tag && 'rel' in tag;
        const isCanonical =
            isRelLink && tag.tagName === 'link' && tag.rel === 'canonical';
        return isCanonical;
    });
};
