import type { MetaDescriptor } from '@remix-run/node';
import type { Graph } from 'schema-dts';

export const createLDSchema = (
    schemas: MetaDescriptor[][]
): MetaDescriptor[] => {
    return schemas
        .filter((schema) => schema.length > 0)
        .map((schema) => {
            return {
                'script:ld+json': {
                    '@context': 'http://schema.org',
                    ...schema?.[0],
                },
            };
        });
};

const isLDSchema = (
    tag: MetaDescriptor
): tag is { 'script:ld+json': Graph } => {
    return 'script:ld+json' in tag;
};

export const mergeLDSchema = (
    mergedMeta: MetaDescriptor[]
): MetaDescriptor[] => {
    const schemasToMerge = mergedMeta
        .filter((tag) => isLDSchema(tag))
        .map((tag) => {
            if (isLDSchema(tag)) return tag['script:ld+json'];
            else return null;
        })
        .filter(Boolean);

    const mergedSchema = {
        'script:ld+json': {
            '@context': 'http://schema.org',
            '@graph': schemasToMerge.flat(),
        },
    };

    // Remove all other ld+schema
    const removeLDSchema = mergedMeta.filter((tag) => !isLDSchema(tag));

    // Return mergedMeta, with a single mergedSchema

    // Temp fix for hydration. Don't add any ld-json schema
    return removeLDSchema.concat(mergedSchema);
};
