import type { MetaArgs } from '@remix-run/node';
import type { StaticParentLoaders } from '.';
import { getRouteData } from '~/modules/root/util/get-route-data';

export const getMetaBaseUrl = (
    metaArgs: MetaArgs<unknown, StaticParentLoaders>
) => {
    const { matches } = metaArgs;
    const { rootData } = getRouteData(matches);
    if (!rootData) return '';
    return 'https://' + rootData.env.host;
};
