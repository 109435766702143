import type { MetaMatches } from '@remix-run/react/dist/routeModules';
import type { StaticParentLoaders } from '~/modules/seo';
import type { AppData } from '../page';
import type { Maybe } from '../type';
import type { RootData } from '~/root';

export const getRouteData = (matches: MetaMatches<StaticParentLoaders>) => {
    const rootData = matches.find((match) => match.id === 'root')
        ?.data as Maybe<RootData>;
    const appData = matches.find((match) => match.id === 'routes/_app')
        ?.data as Maybe<AppData>;

    return {
        rootData,
        appData,
    };
};
