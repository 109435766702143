import type { MetaArgs, MetaDescriptor } from '@remix-run/node';
import type { APCountry } from '~/modules/root';
import invariant from 'tiny-invariant';
import { getMetaBaseUrl } from '../util/create-meta-base-url';
import { getRouteData } from '~/modules/root/util/get-route-data';
import type { PaginationState } from '~/modules/pagination/type';
import type { GetDirectoryPageMetaQuery } from 'generated/graphql';
import type { PaginationOptions } from '~/modules/root/util/get-pagination-opts.server';

export interface AvailabilityZone {
    Country: {
        Code: APCountry;
    };
}

const HREFLANG_OPTIONS = [
    {
        code: 'AU',
        lang: 'en-au',
        host: 'https://archipro.com.au',
    },
    {
        code: 'NZ',
        lang: 'en-nz',
        host: 'https://archipro.co.nz',
    },
];

interface GetCanonicalHreflangOptions {
    forceAllRegions?: boolean;
    professionalCountry?: APCountry | string | null;
    noAlternateLinks?: boolean;
}

const professionalCanonical = (countryCode: APCountry) =>
    HREFLANG_OPTIONS.find((option) => option.code === countryCode);

export type CanonicalHreflangLoaderArgs = () => {
    pagination?: PaginationState | PaginationOptions | null;
    localisation?: GetDirectoryPageMetaQuery['getDirectoryPageMeta'] | null;
};

export const getCanonicalHreflang = (
    props: MetaArgs<CanonicalHreflangLoaderArgs>,
    options: GetCanonicalHreflangOptions = { forceAllRegions: false }
): MetaDescriptor[] => {
    const { data, location, matches } = props;
    const {
        forceAllRegions,
        professionalCountry,
        noAlternateLinks = false,
    } = options;

    const { rootData } = getRouteData(matches);
    invariant(
        rootData?.localisation.country,
        'rootData is required for product category SEO'
    );
    const countryCode: APCountry = rootData.localisation.country;

    // Handle pagination
    const queryParams = new URLSearchParams(location.search);
    const pageQueryParam = queryParams.get('page');
    const selfUrl =
        getMetaBaseUrl(props) + removeFilterPathParameters(location.pathname);
    // Dont append page number query param if first page
    const prevPageUrl =
        data?.pagination?.prevPage === 1
            ? selfUrl
            : selfUrl + `?page=${data?.pagination?.prevPage}`;
    const nextPageUrl = selfUrl + `?page=${data?.pagination?.nextPage}`;
    const pagination =
        pageQueryParam && Number(pageQueryParam) !== 1
            ? `?page=${pageQueryParam}`
            : '';

    const canonicalOverride = data?.localisation?.Canonical;
    const canonicalHref =
        professionalCanonical(professionalCountry || countryCode)?.host +
        removeFilterPathParameters(location.pathname) +
        pagination;
    const availableCountries =
        data?.localisation?.AvailabilityZones?.map((A) => {
            return A?.Country.Code;
        }) ?? [];

    let hrefLangCountries = HREFLANG_OPTIONS;
    // If the page is available in one zone, or has a custom canonical we do not need hreflang
    if (availableCountries.length === 1 || canonicalOverride || canonicalHref)
        hrefLangCountries = [];
    // If the page is available in more than one zone, we need all relevant zones
    if (availableCountries.length > 1) {
        hrefLangCountries = HREFLANG_OPTIONS.filter(({ code }) => {
            return availableCountries.includes(code);
        });
    }
    // E.g Articles are always available in all regions for SEO
    if (forceAllRegions) {
        hrefLangCountries = HREFLANG_OPTIONS;
    }

    if (noAlternateLinks) {
        hrefLangCountries = [];
    }

    return [
        {
            tagName: 'link',
            rel: 'canonical',
            href: removeTrailingSlash(canonicalOverride ?? canonicalHref),
        },
        ...hrefLangCountries.map((country) => {
            return {
                tagName: 'link',
                rel: 'alternate',
                href: removeTrailingSlash(
                    country.host +
                        removeFilterPathParameters(location.pathname) +
                        pagination
                ),
                hrefLang: country.lang,
            };
        }),
        ...(data?.pagination && !data?.pagination?.isFirstPage
            ? [
                  {
                      tagName: 'link',
                      rel: 'prev',
                      href: removeTrailingSlash(prevPageUrl),
                  },
              ]
            : []),
        ...(data?.pagination && !data?.pagination?.isLastPage
            ? [
                  {
                      tagName: 'link',
                      rel: 'next',
                      href: removeTrailingSlash(nextPageUrl),
                  },
              ]
            : []),
    ];
};

export const removeTrailingSlash = (str: string): string =>
    str.replace(/\/+$/, '');

/**
 * SEO Urls should not be impacted by filters.
 * e.g https://archipro.co.nz/products/company_abi-interiors?professionals_size=60
 * should be canonicalised to
 * https://archipro.co.nz/products
 *
 * Remove trailing slash from URL for SEO purposes (avoid wasting crawl budget).
 * URLs with trailing slash are treated as a unique URL, which will flag as a duplicate page
 * when compared to the original page with no trailing slash
 *
 * We can remove all path params that have an underscore (_) these are reserved for filters
 */
const removeFilterPathParameters = (pathname: string): string => {
    const filteredPath = pathname
        .split('/')
        .filter((path) => !path.includes('_'))
        .join('/')
        .replace(/\/$/, '');
    return filteredPath;
};
