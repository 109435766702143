import type { MetaFunction } from '@remix-run/node';
import type { BreadcrumbList } from 'schema-dts';
import type { DirectoryPageMeta } from '~/modules/category/type';
import type { loader as professionalLoader } from '~/modules/professional/page/loaders/professional-page-loader.server';
import type { loader as productLoader } from '~/routes/_app.product.$urlSegment';
import type { loader as projectLoader } from '~/routes/_app.project.$urlSegment._index';
import type { loader as articleLoader } from '~/routes/_app.article.$article._index';
import type { StaticParentLoaders } from '../util';
import { getMetaBaseUrl } from '../util/create-meta-base-url';
import type { CanonicalHreflangLoaderArgs } from '../api';

interface BreadcrumbProps {
    label: string;
    href: string;
}

const HOME_CRUMB = { href: '', label: 'Home' };

// ToDo: Create a new getPageMeta resolver which can handle breadcrumbs for any page
export const createBreadcrumbSchema: MetaFunction<
    | typeof professionalLoader
    | typeof productLoader
    | typeof projectLoader
    | typeof articleLoader
    | (() => {
          directoryPageMeta: DirectoryPageMeta;
      })
    | CanonicalHreflangLoaderArgs,
    StaticParentLoaders
> = (props) => {
    const { data } = props;
    if (!data) return [];
    const baseUrl = getMetaBaseUrl(props);
    // If we have breadcrumbs from detailsPages use that data
    if (data && 'professionalBreadcrumb' in data) {
        const profCrumbs = data.professionalBreadcrumb.map((c) => ({
            href: c.Link,
            label: c.Title,
        }));
        const crumbs = getCrumbs(
            data.initial.Title,
            props.location.pathname,
            profCrumbs
        );
        return generateBreadcrumbSchema(crumbs, props);
    }

    if (data && 'product' in data) {
        const crumbs = getCrumbs(
            data.product.Title,
            props.location.pathname,
            data.product.Breadcrumbs
        );
        return generateBreadcrumbSchema(crumbs, props);
    }

    if (data && 'project' in data) {
        const crumbs = getCrumbs(
            data.project.Title,
            props.location.pathname,
            data.project.Breadcrumbs
        );
        return generateBreadcrumbSchema(crumbs, props);
    }

    if (data && 'details' in data) {
        const crumbs = getCrumbs(
            data.details.title,
            props.location.pathname,
            data.details.breadcrumbs
        );
        return generateBreadcrumbSchema(crumbs, props);
    }

    // If we have breadcrumbs from pageMeta use that data
    if (data && 'directoryPageMeta' in data) {
        const crumbs = [HOME_CRUMB];
        crumbs.push(...data.directoryPageMeta.breadcrumbs);
        return generateBreadcrumbSchema(crumbs, props);
    }

    // Else guess it from url
    const uniqueCrumbs = [...new Set(props.location.pathname.split('/'))];
    const breadcrumbs = uniqueCrumbs.reduce((crumbs, path, idx) => {
        if (idx === 0 && path === '') {
            crumbs.push({ href: baseUrl, label: 'Home' });
        } else {
            const lastCrumb = crumbs[idx - 1]!;
            crumbs.push({
                href: lastCrumb.href + '/' + path,
                label: convertHypenToWords(path),
            });
        }
        return crumbs;
    }, [] as BreadcrumbProps[]);

    const breadcrumbSchema: BreadcrumbList = {
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((crumb, idx) => {
            return {
                '@type': 'ListItem',
                position: idx + 1,
                name: crumb.label,
                item: crumb.href,
            };
        }),
    };
    return [breadcrumbSchema];
};

const convertHypenToWords = (hypen: string) => {
    const removedHypens = hypen.replaceAll('-', ' ');
    return removedHypens.charAt(0).toUpperCase() + removedHypens.slice(1);
};

type FunctionArgs<T> = T extends (args: infer A) => unknown ? A : never;

const generateBreadcrumbSchema = (
    crumbs: BreadcrumbProps[],
    props: FunctionArgs<MetaFunction<unknown, StaticParentLoaders>>
) => {
    const baseUrl = getMetaBaseUrl(props);
    const breadcrumbSchema: BreadcrumbList = {
        '@type': 'BreadcrumbList',
        itemListElement: crumbs.map((crumb, idx) => {
            return {
                '@type': 'ListItem',
                position: idx + 1,
                name: crumb?.label,
                item: baseUrl + crumb?.href,
            };
        }),
    };

    return [breadcrumbSchema];
};

const getCrumbs = (
    title: string,
    location: string,
    crumbs: BreadcrumbProps[]
): BreadcrumbProps[] => {
    return [HOME_CRUMB, ...crumbs, { href: location, label: title }];
};
